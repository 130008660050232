import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/33cbcb6e/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tradeoffs"
    }}>{`Tradeoffs`}</h1>
    <p>{`Da NeoAnalytics als oberstes Gebot die Privatsphäre der Nutzer achtet unterliegen wir einigen Beschränkungen, gewinnen aber viele Vorteile.`}</p>
    <h3 {...{
      "id": "pluspunkte"
    }}>{`Pluspunkte`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`NeoAnalytics ist ohne Einbindung eines Banners DSGVO konform. Wir speichern nie PII (Personal Identifiable Infomration), deren Nutzung zugestimmt werden muss. Stattdessen speichern wir lediglich Besuche auf Ihrer Seite.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Die Privatsphäre Ihrer Nutzer bleibt gewahrt. NeoAnalytics ist nicht in der Lage, registrierte Besuche Nutzern zu zu ordnen, oder Nutzer über Webseiten hinweg zu verfolgen. So bleibt die Privatsphäre Ihrer Nutzer unberührt.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "nachteile"
    }}>{`Nachteile`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Da Tracken über Webseiten hinweg entfällt können wir weniger über die Demografische Verteilung Ihrer Nutzer aussagen.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Da Nutzer nicht getrackt werden, können Sie nicht den Weg eines einzelnen Nutzers durch die Webseite nachvollziehen.`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "der-wert"
    }}>{`Der Wert`}</h3>
    <p>{`Es ist unsere Überzeugung, dass die Pluspunkte unserer Lösung ihre Nachteile aufwiegen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      